import React from 'react';
import { string } from 'prop-types';
import loadable from '@loadable/component';

import { CARD_ROUTE, ACTIVATE_ROUTE, DELETE_ROUTE } from 'constants/navigation';
import { MANAGE_CARD_NAV, ACTIVATE_NAV, DELETE_NAV } from 'constants/font-awesome';

import useStyles from './styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/three'));
const Page = loadable(() => import('components/page'));
const ApplicationFooter = loadable(() => import('components/footer/application-footer'));
const DeleteCardView = loadable(() => import('views/loyalty-card/delete'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedDeleteLoyaltyCardPage = ({ passId = '', title = '' }) => {
  const styles = useStyles();

  return (
      <Page title="Delete card" description={`Delete your Loyalty Card: "${title}"`}>
      <LazyBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
        {`Delete Loyalty Card: "${title}"`}
        </Typography>
        <DeleteCardView passId={passId} title={title}/>
        <ApplicationFooter
          navItems={[
            { route: `${CARD_ROUTE}?passId=${passId}`, icon: MANAGE_CARD_NAV, message: 'Card' },
            { route: `${ACTIVATE_ROUTE}?passId=${passId}`, icon: ACTIVATE_NAV, message: 'Activate' },
            { route: `${DELETE_ROUTE}?passId=${passId}`, icon: DELETE_NAV, message: 'Delete' },
          ]}
          currentItem="Delete"/>
      </Page>
  );
};

SuspendedDeleteLoyaltyCardPage.propTypes = {
  passId: string,
  title: string,
};

export default SuspendedDeleteLoyaltyCardPage;
